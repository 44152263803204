"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var swiper_1 = __importStar(require("swiper"));
// Import necessary Swiper modules
swiper_1.default.use([swiper_1.Pagination, swiper_1.EffectFade, swiper_1.Thumbs]);
/**
 * Represents a Swiper component with image and text slides.
 */
var ImageCopy = /** @class */ (function () {
    /**
     * Initializes the SwiperComponent.
     */
    function ImageCopy() {
        var imageSwipers = document.querySelectorAll('.js-image-copy');
        imageSwipers.forEach(function (swiper) {
            var imageSwiper, textSwiper;
            // Initialize Swiper instances for images and text slides
            imageSwiper = new swiper_1.default('.image-swiper', {
                init: false,
                speed: 500,
                loop: false,
                preventClicks: true,
                allowTouchMove: false,
                watchSlidesProgress: true,
            });
            textSwiper = new swiper_1.default('.text-swiper', {
                init: false,
                speed: 500,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                thumbs: {
                    swiper: imageSwiper,
                },
            });
            // Initialize the Swiper component immediately
            imageSwiper.init();
            textSwiper.init();
        });
    }
    return ImageCopy;
}());
exports.default = ImageCopy;
