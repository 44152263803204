"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Represents a smooth scroll navigation.
 */
var SmoothScrollNav = /** @class */ (function () {
    /**
     * Constructs a SmoothScrollNav instance.
     * @param navbarId - The ID of the navbar element.
     * @param blockId - The ID of the block element.
     */
    function SmoothScrollNav(navbarId, blockId) {
        this.navbarId = navbarId;
        this.currentBlock = null;
        this.logoElement = document.getElementById('logo');
        this.gradientElement = document.querySelector('.o-background-gradient');
        this.blockElement = document.getElementById(blockId);
    }
    /**
     * Initializes the smooth scroll navigation by registering scroll and anchor click events.
     */
    SmoothScrollNav.prototype.init = function () {
        this.registerScrollEvent();
        this.registerAnchorClickEvent();
        this.registerIntersectionObserver();
    };
    SmoothScrollNav.prototype.registerIntersectionObserver = function () {
        var _this = this;
        // Set up the options for the Intersection Observer
        var options = {
            rootMargin: '0px 0px -100% 0px',
            threshold: 0 // This will trigger an update as soon as the block crosses the trigger line
        };
        var observer = new IntersectionObserver(function (entries) {
            // Filter out entries that are intersecting
            var intersectingEntries = entries.filter(function (entry) { return entry.isIntersecting; });
            if (intersectingEntries.length > 0) {
                // If there are intersecting entries, choose the last one (which should be the one that just crossed the trigger line)
                var targetEntry = intersectingEntries[intersectingEntries.length - 1];
                _this.currentBlock = targetEntry.target; // Update the current block
            }
        }, options);
        // Observe elements with the '.js-bkg--light' and '.js-bkg--dark' classes
        var blocks = document.querySelectorAll('.js-bkg--light, .js-bkg--dark');
        blocks.forEach(function (block) { return observer.observe(block); });
    };
    /**
     * Registers the scroll event handler.
     */
    SmoothScrollNav.prototype.registerScrollEvent = function () {
        var _this = this;
        var ticking = false;
        window.onscroll = function () {
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    _this.scrollHandler();
                    ticking = false;
                });
                ticking = true;
            }
        };
    };
    /**
     * Registers the anchor click event handlers.
     */
    SmoothScrollNav.prototype.registerAnchorClickEvent = function () {
        var _this = this;
        // Select all anchor elements within the navbar element
        var anchors = document.querySelectorAll("".concat(this.navbarId, " a"));
        anchors.forEach(function (anchor) {
            anchor.addEventListener('click', function (e) {
                e.preventDefault(); // Prevent default link behavior
                _this.smoothScroll(anchor.getAttribute('href')); // Smooth scroll to the target element
                _this.scrollHandler();
            });
        });
    };
    /**
     * Retrieves the topmost visible element matching the given selectors from the page.
     * @param {string[]} targetSelectors - The classes or ids of the target elements.
     * @returns {Element | false} The topmost visible element matching the target selectors, or false if no visible element is found.
     */
    SmoothScrollNav.prototype.getTopmostVisibleElementBySelectors = function (targetSelectors) {
        var x = window.innerWidth / 2;
        var y = 0;
        var elementsAtTop = document.elementsFromPoint(x, y);
        // Filter out elements that are less than 20% visible
        var visibleElements = elementsAtTop.filter(function (element) {
            var rect = element.getBoundingClientRect();
            var visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
            return visibleHeight / rect.height > 0.2;
        });
        // Search the visible elements for the target element
        var targetElement = visibleElements.find(function (element) {
            return targetSelectors.some(function (selector) { return element.matches(selector); });
        });
        return targetElement !== null && targetElement !== void 0 ? targetElement : false; // Return the topmost visible target element or false if not found
    };
    /**
     * Handles the scroll event. Updates the display and gradient based on the scroll position.
     */
    SmoothScrollNav.prototype.scrollHandler = function () {
        var blockElementBottom = this.blockElement.offsetTop + this.blockElement.offsetHeight;
        if (window.scrollY > blockElementBottom) {
            // If scroll position is greater than the bottom offset of the block element
            this.toggleElementDisplay({ id: 'logo', active: true }); // Display logo element
            this.toggleElementDisplay({ id: '.o-background-gradient', active: true }); // Display gradient element
            if (this.currentBlock) {
                var gradientType = this.currentBlock.classList.contains('js-bkg--light') ? 'light' : 'dark';
                this.toggleGradientType(gradientType); // Set gradient type to light or dark
            }
            else {
                this.toggleGradientType('none'); // Remove the gradient type
            }
        }
        else {
            this.toggleGradientType('none'); // Remove the gradient type
            // If scroll position is less than or equal to the bottom offset of the block element
            this.toggleElementDisplay({ id: 'logo', active: false }); // Hide logo element
            this.toggleElementDisplay({ id: '.o-background-gradient', active: false }); // Hide gradient element
        }
    };
    /**
     * Scrolls smoothly to the target element with the specified targetId.
     * @param targetId - The ID of the target element.
     */
    SmoothScrollNav.prototype.smoothScroll = function (targetId) {
        if (!targetId)
            return;
        var targetElement = document.querySelector(targetId);
        //targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center'});
        var yOffset = 40;
        var y = (targetElement === null || targetElement === void 0 ? void 0 : targetElement.getBoundingClientRect().top) + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };
    /**
     * Toggles the 'active' CSS class on the specified element.
     * @param id - The ID or class selector of the element.
     * @param display Whether or not to display the element.
     */
    SmoothScrollNav.prototype.toggleElementDisplay = function (_a) {
        var id = _a.id, active = _a.active;
        // Select the element based on the provided ID (can be ID or class selector)
        var element = id.startsWith('.') ? document.querySelector(id) : document.getElementById(id);
        if (element) {
            if (active)
                element.classList.add('active'); // Toggle the presence of the 'active' CSS class on the element
            else
                element.classList.remove('active'); // Toggle the presence of the 'active' CSS class on the element
        }
    };
    /**
     * Toggles the gradient type (light, dark, or none) of the gradient element.
     * @param {('light' | 'dark' | 'none')} type - The gradient type to apply.
     *    - 'light': Applies a light gradient type.
     *    - 'dark': Applies a dark gradient type.
     *    - 'none': Removes the gradient type.
     */
    SmoothScrollNav.prototype.toggleGradientType = function (type) {
        var navbarElement = document.querySelector(this.navbarId);
        if (this.gradientElement && navbarElement) {
            // Remove the existing gradient type classes
            this.gradientElement.classList.remove('o-background-gradient--light', 'o-background-gradient--dark');
            navbarElement.classList.remove('is-light', 'is-dark');
            // Add the new gradient type class, unless the type is 'none'
            if (type !== 'none') {
                this.gradientElement.classList.add("o-background-gradient--".concat(type));
                navbarElement.classList.add("is-".concat(type));
            }
        }
    };
    return SmoothScrollNav;
}());
exports.default = SmoothScrollNav;
